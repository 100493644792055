import React from 'react';
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import p08cerdo01Audio from "../audio/p08cerdo01Audio.m4a"
import p08cerdo02Audio from "../audio/p08cerdo02Audio.m4a"
import p08cerdo03Audio from "../audio/p08cerdo03Audio.m4a"
import p08colaCerdosAudio from "../audio/p08colaCerdosAudio.m4a"
import p08VO from "../audio/p08VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

class Page08 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: false,
                   cencerro: images["p08_cencerro.png"],
                 }
  }
  playAudio = (x) => {
    const audioEl = document.getElementsByClassName("audio-element")[x]
     audioEl.play()
}
handleCencerroClick = () => {
  this.setState({ active: !this.state.active }, () => {
      if(this.state.active){
        setTimeout( () => {this.setState({cencerro: images["resetBotonTrans.png"]})}, 1000 );
        setTimeout( () => {this.playAudio(3)}, 350)
      }
      else { this.setState({cencerro: images["p08_cencerro.png"]}) }
    })
}
  render() {
    return (
      <div className="main-container">
      <Link to="/Page07">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page09">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
        <div className="centered-container">
          <div className="backContainer004">
          <StaticQuery
            query={graphql`
              query {
                imageOne: file(relativePath: { eq: "p08_back.jpg" }) {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
      render={data => (
        <Img fluid={data.imageOne.childImageSharp.fluid} />
      )}
    />
          </div>
          <div className="p08Cuentamelo"  onClick={() => this.playAudio(4)}>
            <img className="hw" src={images["botonCuentamelo.png"]} alt="" />
          </div>
          <div className={"_idContainer112" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoBottomColaTorcida.png"]} alt="" />
          </div>
          <div className={"_idContainer113" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoBottomCola.png"]} alt="" />
          </div>
          <div className="_idContainer114">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoBottom.png"]} alt="" />
          </div>
          <div className="_idContainer115">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoBottomBoca.png"]} alt="" />
          </div>
          <div className={"_idContainer116" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTopColaTorcida.png"]} alt="" />
          </div>
          <div className={"_idContainer117" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTopCola.png"]} alt="" />
          </div>
          <div className="_idContainer118">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTop.png"]} alt="" />
          </div>
          <div className="_idContainer122">
            <div className="_idContainer119">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTopOrejaDer.png"]} alt="" />
            </div>
            <div className="_idContainer120">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTopCabeza.png"]} alt="" />
            </div>
            <div className="_idContainer121">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoTopOrejaIzq.png"]} alt="" />
            </div>
          </div>
          <div className={"_idContainer123" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeColaTorcida.png"]} alt="" />
          </div>
          <div className={"_idContainer124" + (this.state.active ? " active" : "")} >
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeCola.png"]} alt="" />
          </div>
          <div className="_idContainer125">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrande.png"]} alt="" />
          </div>
          <div className="_idContainer129">
            <div className="_idContainer126">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeOrejaDer.png"]} alt="" />
            </div>
            <div className="_idContainer127">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeCabeza.png"]} alt="" />
            </div>
            <div className="_idContainer128">
              <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeOrejaIzq.png"]} alt="" />
            </div>
          </div>
          <div className="_idContainer130">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerditoBottomTop.png"]} alt="" />
          </div>
          <div className="_idContainer131">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_lodoCerditoTop.png"]} alt="" />
          </div>
          <div className="_idContainer132">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p08_cerdoGrandeTop.png"]} alt="" />
          </div>
          <div className="_idContainer133 Basic-Text-Frame">
            <span>Los puercos torcían el rabo.</span>
          </div>
          <div className="_idContainer134">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4259.png"]} onClick={() => this.playAudio(0)} alt="" />
          </div>
          <div className="_idContainer135">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4260.png"]} onClick={() => this.playAudio(1)} alt="" />
          </div>
          <div className="_idContainer136">
            <img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["4261.png"]} onClick={() => this.playAudio(2)} alt="" />
          </div>
          <div className={"_idContainer137" + (this.state.active ? " active" : "")} onClick={this.handleCencerroClick}>
            <img className="cencerroReset _idGenObjectAttribute-1 _idGenObjectAttribute-2" src={this.state.cencerro} alt="" />
          </div>

          <audio className="audio-element">
            <source src={p08cerdo01Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p08cerdo02Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p08cerdo03Audio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p08colaCerdosAudio}></source>
          </audio>
          <audio className="audio-element">
            <source src={p08VO}></source>
          </audio>
        </div>
      <Footer />
      </div>
    )
  }
  }

export default Page08;
